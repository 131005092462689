import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ActivityCard from "../../../components/ActivityCard";
import formatDateTime from "../../../utils/formatDateTime";
import { setViewportMeta } from "../../../utils/viewport";
import {
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
} from "@chakra-ui/react";

function ActivityTask() {
  const { id } = useParams();
  const token = window.localStorage.getItem("token");

  const [errorDisplay, setErrorDisplay] = useState(null);
  const [activities, setActivities] = useState({
    title: "",
    instruction: "",
    date_begin: "",
    date_end: "",
    narrations: [],
  });

  useEffect(() => {
    // Set the viewport meta tag for this component
    setViewportMeta("width=device-width, initial-scale=0.4"); // Customize the content as needed

    // Cleanup the dynamically added meta tag when the component is unmounted
    return () => {
      document.head.removeChild(
        document.querySelector('meta[name="viewport"]')
      );
    };
  }, []);

  useEffect(() => {
    // Fetch the activity data by ID
    const fetchActivityData = async () => {
      try {
        const response = await axios.get(
          `https://api.thekuasar.com/activity/${id}`
        );

        const fetchedData = response.data.data;
        console.log(fetchedData);

        for (let i = 0; i < fetchedData.narrations.length; i++) {
          if (fetchedData.narrations[i].narration === "null") {
            fetchedData.narrations[i].narration = "";
          }
          if (fetchedData.narrations[i].retweet_url === "null" || fetchedData.narrations[i].retweet_url === "undefined") {
            fetchedData.narrations[i].retweet_url = "";
          }
        }
        
        setActivities({
          title: fetchedData.title,
          instruction: fetchedData.instruction,
          date_begin: fetchedData.date_begin,
          date_end: fetchedData.date_end,
          narrations: fetchedData.narrations.map((narration) => ({
            id: narration.id,
            text: narration.narration,
            media: narration.media.map((mediaItem) => mediaItem),
            link: narration.tweet_url,
            retweet: narration.retweet_url,
          })),
        });
      } catch (error) {
        console.error("Error fetching activity data:", error);
        setErrorDisplay(error);
      }
    };

    fetchActivityData();
  }, [id]);

  const formattedDateBegin = formatDateTime(activities.date_begin);
  const formattedDateEnd = formatDateTime(activities.date_end);

  return (
    <div style={{ overflowY: "auto", backgroundColor: "blue.50", height: "fit-content" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        backgroundColor="blue.50"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
          marginTop={20}
          marginBottom={70}
        >
          <Box minW={{ base: "100%" }} marginX={5} marginY={5}>
            <Stack
              spacing={4}
              justifyContent="center"
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              paddingLeft={5}
              paddingRight={5}
            >
              <Heading color="black" marginBottom={4}>
                {activities.title}
              </Heading>

              <Text>Time Start - Time End</Text>
              <Text fontWeight="bold">
                {formattedDateBegin} - {formattedDateEnd}
              </Text>

              <Divider />

              <Text>Instruction</Text>
              <Text fontWeight="bold">{activities.instruction}</Text>

              <Divider />

              {activities.narrations.length > 0 && (
                <Stack>
                  {activities.narrations.map((narration, index) => (
                    <ActivityCard
                      key={index}
                      narration={narration}
                      token={token} // Pass the token as a prop
                      id={id} // Pass the id as a prop
                    />
                  ))}
                </Stack>
              )}

              {errorDisplay && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Display activity failed</AlertTitle>
                  <AlertDescription>{errorDisplay.message}</AlertDescription>
                </Alert>
              )}
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </div>
  );
}

export default ActivityTask;
