import React, { useState, useRef, useEffect } from "react";
import { Link as ReactLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Flex,
  Heading,
  Input,
  Button,
  FormLabel,
  Stack,
  Box,
  FormControl,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Textarea,
  Link,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";

function UpdateActivity() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [deleteQueue, setDeleteQueue] = useState([]); // Queue for narration deletion
  const [responseActivity, setResponseActivity] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { hasCopied, onCopy } = useClipboard(
    `https://demoportal.thekuasar.com/task/${id}`
  );

  const [data, setData] = useState({
    title: "",
    instruction: "",
    date_begin: "",
    date_end: "",
    narrations: [{ text: "", media: [""], retweet: "" }],
  });

  const [retweetDisabled, setRetweetDisabled] = useState([]);
  const [narrationDisabled, setNarrationDisabled] = useState([]);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Use useEffect to fetch activity data when the component mounts
  useEffect(() => {
    const token = window.localStorage.getItem("token");

    // Fetch the activity data by ID
    const fetchActivityData = async () => {
      try {
        const response = await axios.get(
          `https://api.thekuasar.com/activities/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const fetchedData = response.data.data;
        console.log(fetchedData);

        for (let i = 0; i < fetchedData.narrations.length; i++) {
          if (fetchedData.narrations[i].narration === "null") {
            fetchedData.narrations[i].narration = "";
          }
          if (
            fetchedData.narrations[i].retweet_url === "null" ||
            fetchedData.narrations[i].retweet_url === "undefined"
          ) {
            fetchedData.narrations[i].retweet_url = "";
          }
        }

        setData({
          title: fetchedData.title,
          instruction: fetchedData.instruction,
          date_begin: fetchedData.date_begin,
          date_end: fetchedData.date_end,
          narrations: fetchedData.narrations.map((narration) => ({
            id: narration.id,
            text: narration.narration,
            retweet: narration.retweet_url,
            media: narration.media.map((mediaItem) => mediaItem),
          })),
        });

        console.log(data);

        // if text null or contains "" then it's disabled, narrationDisabled = true
        // if rt null or contains "" then it's disabled, retweetDisabled = true

        console.log(data);

        const narrationDisabledArray = fetchedData.narrations.map(
          (narration) => !narration.narration || narration.narration === ""
        );

        const retweetDisabledArray = fetchedData.narrations.map(
          (narration) => !narration.retweet_url || narration.retweet_url === ""
        );

        setNarrationDisabled(narrationDisabledArray);
        setRetweetDisabled(retweetDisabledArray);

        console.log(narrationDisabledArray);
        console.log(retweetDisabledArray);
      } catch (error) {
        console.error("Error fetching activity data:", error);
        setError(error);
      }
    };

    fetchActivityData();
  }, [id]);

  function convertToCustomFormat(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const formattedDateBegin = convertToCustomFormat(data.date_begin);
  const formattedDateEnd = convertToCustomFormat(data.date_end);

  const handleTitleChange = (e) => {
    setData({ ...data, title: e.target.value });
  };

  const handleInstructionChange = (e) => {
    setData({ ...data, instruction: e.target.value });
  };

  const handleDateBeginChange = (e) => {
    setData({ ...data, date_begin: e.target.value });
  };

  const handleDateEndChange = (e) => {
    setData({ ...data, date_end: e.target.value });
  };

  const handleNarrationTextChange = (e, index) => {
    const { value } = e.target;
    const updatedNarrations = [...data.narrations];
    updatedNarrations[index].text = value;
    setData({ ...data, narrations: updatedNarrations });

    // Check if "Tweet Narration" field is empty to enable/disable "Retweet URL" field
    const updatedDisabled = [...retweetDisabled]; // Use the retweetDisabled array
    if (value.trim() === "") {
      updatedDisabled[index] = false; // Enable "Retweet URL" for this specific narration
    } else {
      updatedDisabled[index] = true; // Disable "Retweet URL" for this specific narration
    }
    setRetweetDisabled(updatedDisabled);
  };

  const handleNarrationRetweetChange = (e, index) => {
    const { value } = e.target;
    const updatedNarrations = [...data.narrations];
    updatedNarrations[index].retweet = value;
    setData({ ...data, narrations: updatedNarrations });

    // Check if "Retweet URL" field is empty to enable/disable "Narration text" field
    const updatedDisabled = [...narrationDisabled]; // Use the narrationDisabled array
    if (value.trim() === "") {
      updatedDisabled[index] = false; // Enable "Narration text" for this specific narration
    } else {
      updatedDisabled[index] = true; // Disable "Narration text" for this specific narration
    }
    setNarrationDisabled(updatedDisabled);
  };

  const handleRemoveNarration = (narrationIndex) => {
    // Add the index to the deletion queue
    setDeleteQueue((prevQueue) => [...prevQueue, narrationIndex]);

    // Remove the narration from data
    const updatedData = { ...data };
    updatedData.narrations.splice(narrationIndex, 1);
    setData(updatedData);
  };

  const deleteNarration = async (narrationId) => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.delete(
        `https://api.thekuasar.com/activities/narration/${narrationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(`Narration ${narrationId} deleted:`, response.data);

      // Lakukan pembaruan state setelah penghapusan berhasil
      const updatedNarrations = data.narrations.filter(
        (narration) => narration.id !== narrationId
      );
      setData({ ...data, narrations: updatedNarrations });
    } catch (error) {
      console.error(`Error deleting narration ${narrationId}:`, error);
      setError(`Error deleting narration ${narrationId}:`, error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("token");

    const jsonData = {
      title: data.title,
      instruction: data.instruction,
      date_begin: data.date_begin,
      date_end: data.date_end,
    };

    try {
      // Step 1: Send the PUT request to update the main activity data
      const response1 = await axios.put(
        `https://api.thekuasar.com/activities/${id}`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );

      setResponseActivity(response1);
      console.log("Activity updated:", response1.data);

      // Step 2: Iterate through narrations and send PUT requests for each
      for (let i = 0; i < data.narrations.length; i++) {
        const narration = data.narrations[i];
        const narrationId = narration.id;

        // Check if the narration index is in the delete queue
        if (deleteQueue.includes(i)) {
          // If in the queue, skip this narration (don't send a PUT request to delete it)
          continue;
        }

        const formData = new FormData();

        let response2;

        if (narration.text === "") {
          formData.append(`narration`, null);
        } else {
          formData.append(`narration`, narration.text);
        }
        formData.append(`tweet_url`, String(narration.retweet));
        console.log(narration.text);
        console.log(narration.retweet);

        response2 = await axios.put(
          `https://api.thekuasar.com/activities/narration/${narrationId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(`Narration ${i + 1} updated:`, response2.data);
      }
      // Delete narrations in the delete queue
      for (let i = 0; i < deleteQueue.length; i++) {
        const narrationIndex = deleteQueue[i];
        const narrationIdToDelete = data.narrations[narrationIndex].id;
        await deleteNarration(narrationIdToDelete);
      }

      setSuccess("Activity updated!");
    } catch (error) {
      console.error("Error updating activity:", error);
      setError("Error updating activity:", error);
    }
  };

  // Use useEffect to handle response changes
  useEffect(() => {
    if (responseActivity && success) {
      setIsPopupOpen(true);
    }
  }, [responseActivity, success]);

  return (
    <div style={{ overflowY: "auto" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
          marginBottom={5}
        >
          <Box minW={{ base: "90%", md: "550px" }}>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Stack
                spacing={4}
                justifyContent="center"
                alignItems="center"
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <Heading color="black" marginBottom={1}>
                  UPDATE ACTIVITY
                </Heading>

                <HStack>
                  <FormControl>
                    <FormLabel>Date Time Begin</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={formattedDateBegin}
                      onChange={handleDateBeginChange}
                      required
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date Time End</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={formattedDateEnd}
                      onChange={handleDateEndChange}
                      required
                    />
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    type="text"
                    placeholder="Title"
                    value={data.title}
                    onChange={handleTitleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Instruction</FormLabel>
                  <Textarea
                    type="text"
                    placeholder="Instruction"
                    value={data.instruction}
                    onChange={handleInstructionChange}
                  />
                </FormControl>
                {data.narrations.map((narration, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <Text fontSize="sm" color="gray.500">
                      Please fill either the narration text or the retweet URL,
                      but not both.
                    </Text>

                    <FormControl marginTop={3} marginBottom={3}>
                      <FormLabel>Tweet Narration</FormLabel>
                      <Textarea
                        type="text"
                        placeholder="Tweet Narration"
                        name={`narrations_${index}_text`}
                        value={data.narrations[index].text}
                        onChange={(e) => handleNarrationTextChange(e, index)}
                        required
                        disabled={narrationDisabled[index]}
                      />
                    </FormControl>
                    <FormControl marginBottom={3}>
                      <FormLabel>Retweet URL</FormLabel>
                      <Textarea
                        type="text"
                        placeholder="Tweet URL"
                        name={`narrations_${index}_retweet`}
                        value={data.narrations[index].retweet}
                        onChange={(e) => handleNarrationRetweetChange(e, index)}
                        required
                        disabled={retweetDisabled[index]}
                      />
                    </FormControl>

                    {index > 0 && (
                      <>
                        <Button
                          variant="outline"
                          colorScheme="red"
                          size="sm"
                          onClick={onOpen}
                        >
                          Remove Narration
                        </Button>
                        <AlertDialog
                          isOpen={isOpen}
                          leastDestructiveRef={cancelRef}
                          onClose={onClose}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader
                                fontSize="lg"
                                fontWeight="bold"
                              >
                                Delete Narration
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                Are you sure? All user activities related with
                                this Narration will be deleted as well.
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                  Cancel
                                </Button>
                                <Button
                                  colorScheme="red"
                                  onClick={() => {
                                    onClose();
                                    handleRemoveNarration(index);
                                  }}
                                  ml={3}
                                >
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>
                      </>
                    )}
                  </div>
                ))}

                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                >
                  Submit
                </Button>
                <Link width="full" href="/admin/activities">
                  <Button
                    borderRadius={0}
                    variant="outline"
                    colorScheme="red"
                    width="full"
                  >
                    Cancel
                  </Button>
                </Link>

                {error && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Update activity failed</AlertTitle>
                    <AlertDescription>{error.message}</AlertDescription>
                  </Alert>
                )}
                {success && (
                  <Alert status="success">
                    <AlertIcon />
                    <AlertTitle>Update activity success!</AlertTitle>
                    <AlertDescription>{success}</AlertDescription>
                  </Alert>
                )}
              </Stack>
            </form>
          </Box>
        </Stack>
        {/* Tampilkan pop-up jika isPopupOpen true */}
        <AlertDialog
          isOpen={isPopupOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsPopupOpen(false)} // Tutup pop-up saat tombol Cancel ditekan
          width={"100%"}
        >
          <AlertDialogOverlay width={"100%"} justifyContent={"center"}>
            <AlertDialogContent
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold" width={"100%"}>
                Activity Updated!
              </AlertDialogHeader>

              <AlertDialogBody width={"100%"}>
                {success && (
                  <>
                    Your activity has been successfully created. Here's the
                    link:
                    <br />
                    <ReactLink to={`/task/${id}`}>
                      <Text color={"blue.500"}>View Activity</Text>
                    </ReactLink>
                    <br />
                    <Flex alignItems="center">
                      <Input
                        value={`https://demoportal.thekuasar.com/task/${id}`}
                        isReadOnly
                        placeholder={`https://demoportal.thekuasar.com/task/${id}`}
                      />
                      <Button onClick={onCopy} ml={2}>
                        {hasCopied ? "Copied" : "Copy"}
                      </Button>
                    </Flex>
                  </>
                )}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setIsPopupOpen(false); // Tutup pop-up saat tombol OK ditekan
                    navigate("/admin/activities"); // Navigasi ke halaman aktivitas
                  }}
                >
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </div>
  );
}

export default UpdateActivity;
