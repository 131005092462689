import "./App.css";
import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminRegister from "./pages/Admin/AdminRegister";
import UserLogin from "./pages/User/UserLogin";
import UserRegister from "./pages/User/UserRegister";
import CreateActivity from "./pages/Admin/Activity/CreateActivity";
import ReadActivity from "./pages/Admin/Activity/ReadActivity";
import UpdateActivity from "./pages/Admin/Activity/UpdateActivity";
import UserActivityList from "./pages/User/Activity/UserActivityList";
import ActivityTask from "./pages/User/Activity/ActivityTask";
import UserActivityVerification from "./pages/Admin/Activity/UserActivityVerification";
import PrivateRoutesAdmin from "./routes/PrivateRoutesAdmin";
import PrivateRoutesVolunteer from "./routes/PrivateRoutesVolunteer";
import UserActivityTaskList from "./pages/Admin/Activity/UserActivityTaskList";
import VolunteerLogin from "./pages/User/VolunteerLogin";
import ChangeVolunteerPassword from "./pages/Admin/ChangeVolunteerPassword";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/admin-login" element={<AdminLogin />} />
          {/* <Route path="/admin-register" element={<AdminRegister />} /> */}
          {/* <Route path="/volunteer-login" element={<VolunteerLogin />} /> */}

          <Route element={<PrivateRoutesAdmin />}>
            <Route
              path="/admin/change-volunteer-password"
              element={<ChangeVolunteerPassword />}
            />
            <Route path="/admin/create-activity" element={<CreateActivity />} />
            <Route path="/admin/activities" element={<ReadActivity />} />
            <Route
              path="/admin/update-activity/:id"
              element={<UpdateActivity />}
            />
            <Route
              path="/admin/user-activities"
              element={<UserActivityTaskList />}
            />
            <Route
              path="/admin/user-activities/:id"
              element={<UserActivityVerification />}
            />
          </Route>

          {/* <Route path="/activities" element={<UserActivityList />} /> */}
          <Route path="/task/:id" element={<ActivityTask />} />

          {/* <Route path="/user-login" element={<UserLogin />} />
          <Route path="/user-register" element={<UserRegister />} /> */}
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
