// UserActivityVerification.js
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./pagination.css";
import ReactPaginate from "react-paginate";
import { setViewportMeta } from "../../../utils/viewport";
import {
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

const UserActivityVerification = () => {
  const { id } = useParams();

  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [pagesQuantity, setPagesQuantity] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Set the viewport meta tag for this component
    setViewportMeta('width=device-width, initial-scale=0.4'); // Customize the content as needed

    // Cleanup the dynamically added meta tag when the component is unmounted
    return () => {
      document.head.removeChild(document.querySelector('meta[name="viewport"]'));
    };
  }, []);
  
  const fetchActivities = async (page) => {
    const token = window.localStorage.getItem("token");
    console.log("Token:", token);

    let url = `https://api.thekuasar.com/activities/detail-users-activity/${id}`;
    if (page > 1) {
      // Append the page query parameter for pages other than the first
      url += `?page=${page}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Response data:", response);

      const fetchedActivities = response.data.data.users || []; // Handle case where data.user_activity is undefined
      // Sort the activities by ID before setting them in the state
      const sortedActivities = fetchedActivities.sort((a, b) => a.id - b.id);
      setActivities(sortedActivities);
      console.log(fetchedActivities);
      setPagesQuantity(response.data.data.total);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchActivities(newPage);
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        alignItems="center"
      >
        <Heading color="black" marginTop={3} marginBottom={2}>
          USER ACTIVITIES
        </Heading>

        <Link to="/admin/user-activities">
            <Button
              marginLeft={2}
              colorScheme="blue"
              size="sm"
              marginTop={2}
              marginBottom={2}
            >
              See User Activities by Task
            </Button>
          </Link>
          
        <Table variant="striped" colorScheme="blue" width="100%" size="sm">
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>Twitter Account</Th>
              <Th>User Id</Th>
              <Th>Activity Id</Th>
              <Th>Narration Id</Th>
              <Th>Tweet Url</Th>
              <Th>Collected</Th>
            </Tr>
          </Thead>
          <Tbody>
            {activities && activities.length > 0 ? (
              activities.map((activity, index) => (
                <Tr key={activity.id}>
                  <Td>{index + 1}</Td>
                  <Td>{activity.twitter_account}</Td>
                  <Td>{activity.user_id}</Td>
                  <Td>{activity.activity_id}</Td>
                  <Td>{activity.narration_id}</Td>
                  <Td>
                    {activity.tweet_url ? (
                      <a
                        href={activity.tweet_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {activity.tweet_url}
                      </a>
                    ) : (
                      <span>No Tweet Url</span>
                    )}
                  </Td>
                  <Td>
                    {activity.is_collect === true ? (
                      <span>Done</span>
                    ) : (
                      <span>-</span>
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="7">No activities found</Td>
              </Tr>
            )}
          </Tbody>
        </Table>

        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>
              An error occurred while fetching activities:
            </AlertTitle>
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}

        <ReactPaginate
          breakLabel="..."
          nextLabel="next"
          onPageChange={(selectedPage) =>
            handlePageChange(selectedPage.selected + 1)
          }
          pageCount={pagesQuantity}
          previousLabel="previous"
          renderOnZeroPageCount={false}
          containerClassName="pagination-container"
          pageClassName="pagination-page"
          breakClassName="pagination-break"
          previousClassName="pagination-previous"
          nextClassName="pagination-next"
          activeClassName="pagination-active"
        />
      </Flex>
    </div>
  );
};

export default UserActivityVerification;
