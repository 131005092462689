// VolunteerLogin.js

import React, { useState } from "react";
import axios from "axios";
import { Link as LinkReact, useNavigate } from "react-router-dom";
import {
  Link,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  FormLabel,
  Stack,
  Box,
  FormControl,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
  Text,
} from "@chakra-ui/react";

const ChangeVolunteerPassword = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleShowOldPasswordClick = () => setShowOldPassword(!showOldPassword);
  const handleShowNewPasswordClick = () => setShowNewPassword(!showNewPassword);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://api.thekuasar.com/admin/change-volunteer-password",
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Change volunteer password successful");
      setSuccess(response);

      navigate("/admin/activities");
    } catch (error) {
      console.error("Change failed:", error);
      setError("Change failed. Please check your input.");
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "450px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              height={450}
              justifyContent="center"
              alignItems="center"
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <Heading color="black" marginBottom={5}>
                CHANGE VOLUNTEER PASSWORD
              </Heading>
              
                <FormControl>
                  <FormLabel>Old Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showOldPassword ? "text" : "password"}
                      placeholder="Old Password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                    <InputRightElement width="4rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleShowOldPasswordClick}
                      >
                        {showOldPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel>New Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <InputRightElement width="4rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={handleShowNewPasswordClick}
                      >
                        {showNewPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                  onClick={handleSubmit}
                >
                  Change Password
                </Button>
              
              {error && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle>Change failed</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              {success && (
                  <Alert status="success">
                  <AlertIcon />
                  <AlertTitle>Change success!</AlertTitle>
                  <AlertDescription>{success}</AlertDescription>
                </Alert>
              )}
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default ChangeVolunteerPassword;
