import React, { useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  CardFooter,
  Box,
  Image,
  Stack,
  Text,
  Button,
  Divider,
  Link,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

const ActivityCard = ({ narration, token, id }) => {
  const [verificationLink, setVerificationLink] = useState("");

  const [errorVerif, setErrorVerif] = useState(null);
  const [successVerif, setSuccessVerif] = useState(null);

  function convertToTwitterLink(narration) {
    // Replace spaces with '%20' and '#' with '%23'
    const formattedNarration = narration.text
      .replace(/ /g, "%20")
      .replace(/#/g, "%23");

    const mediaUrls = narration.media
      .map((mediaItem) => `${encodeURIComponent(mediaItem.uri)}`)
      .join("+");

    // Build the Twitter intent link
    const twitterIntentURL = `https://twitter.com/intent/tweet?text=${formattedNarration}+${mediaUrls}`;

    return twitterIntentURL;
  }

  const handleVerificationLinkSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Submitting verification link...");
      console.log(token);
      console.log(id);
      const headers = token
        ? {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        : { "Content-Type": "application/json" };

      console.log(verificationLink + ": " + id + ": " + narration.id);

      const response = await axios.post(
        `https://api.thekuasar.com/activity`,
        {
          additional_instructions: null,
          tweet_url: verificationLink,
          activity_id: id,
          narration_id: narration.id,
        },
        { headers }
      );

      console.log(verificationLink);
      console.log(response);
      if (response) {
        console.log("Verification link submitted successfully");
        setSuccessVerif("Successfully submitted");
      }
      setVerificationLink("");
    } catch (error) {
      if (error.message === "Request failed with status code 409") {
        console.log("Verification link submitted successfully with 409");
        setSuccessVerif("Successfully submitted");
      } else {
        console.error("Error submit verification link:", error.message);
        console.log(error.message);
        setErrorVerif(error);
      }
    }
  };

  return (
    <>
      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
      >
        <Stack>
          <CardBody>
            {narration.retweet !== "" ? (
              <Text py="2">Retweet: {narration.retweet}</Text>
            ) : (
              <Text py="2">{narration.text}</Text>
            )}
          </CardBody>
          <CardFooter justifyContent="space-between">
            <Flex flexDirection="column">
            <Flex flexDirection="row">
                {narration.retweet !== "" ? (
                  <Link href={narration.retweet} isExternal>
                    <Button
                      variant="solid"
                      colorScheme="blue"
                      width="xs"
                      fontSize="lg"
                      marginRight={3}
                    >
                      Retweet
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    width="xs"
                    fontSize="lg"
                    marginRight={3}
                    isDisabled
                  >
                    Retweet
                  </Button>
                )}

                {narration.text !== "" ? (
                  <Link
                    marginBottom={2}
                    href={convertToTwitterLink(narration)}
                    isExternal
                  >
                    <Button
                      variant="solid"
                      colorScheme="blue"
                      width="xs"
                      fontSize="lg"
                    >
                      Tweet
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    width="xs"
                    fontSize="lg"
                    isDisabled
                  >
                    Tweet
                  </Button>
                )}
              </Flex>

              <form onSubmit={handleVerificationLinkSubmit}>
                <FormControl marginTop={3}>
                  <InputGroup>
                    <Input
                      borderColor="black"
                      size="lg"
                      type="text"
                      placeholder="Tweet Link"
                      value={verificationLink}
                      onChange={(e) => setVerificationLink(e.target.value)}
                      required
                    />
                    <InputRightElement width="9.5rem">
                      <Button
                        variant="solid"
                        colorScheme="green"
                        h="2.00rem"
                        size="md"
                        type="submit"
                        marginTop={2}
                        marginRight={2}
                      >
                        Verification Link
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </form>
            </Flex>
          </CardFooter>
        </Stack>
      </Card>
      {errorVerif && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Verification failed</AlertTitle>
          <AlertDescription>{errorVerif.message}</AlertDescription>
        </Alert>
      )}
      {successVerif && (
        <Alert status="success">
          <AlertIcon />
          <AlertTitle>Verification success!</AlertTitle>
          <AlertDescription>{successVerif}</AlertDescription>
        </Alert>
      )}
      <Divider />
    </>
  );
};

export default ActivityCard;
