import { Outlet, Navigate, useLocation } from "react-router-dom";

const PrivateRoutesAdmin = () => {
  const location = useLocation();
  const token = window.localStorage.getItem("token");
  const role = window.localStorage.getItem("role");

  if (token && role === "ADMIN") {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default PrivateRoutesAdmin;
