// ReadActivity.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./pagination.css";
// import "./table.css";
import { setViewportMeta } from "../../../utils/viewport";
import {
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Spacer,
  Box,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import formatDateTime from "../../../utils/formatDateTime";

const ReadActivity = () => {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [pagesQuantity, setPagesQuantity] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [activityToDelete, setActivityToDelete] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const handleDeleteClick = (id) => {
    setActivityToDelete(id);
    onOpen();
  };

  useEffect(() => {
    // Set the viewport meta tag for this component
    setViewportMeta('width=device-width, initial-scale=0.4'); // Customize the content as needed

    // Cleanup the dynamically added meta tag when the component is unmounted
    return () => {
      document.head.removeChild(document.querySelector('meta[name="viewport"]'));
    };
  }, []);

  // Function to fetch activities for a specific page
  const fetchActivities = async (page) => {
    const token = window.localStorage.getItem("token");
    console.log("Token:", token);
    let url = "https://api.thekuasar.com/activities/";

    if (page > 1) {
      // Append the page query parameter for pages other than the first
      url += `?page=${page}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Response data:", response.data);

      const fetchedActivities = response.data.data.activity;
      setActivities(fetchedActivities);
      setPagesQuantity(response.data.data.total);
      console.log(activities);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setError(error);
    }
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchActivities(newPage);
  };

  useEffect(() => {
    fetchActivities(currentPage); // Fetch activities for the initial page
  }, [currentPage]);

  const handleDelete = async (id) => {
    const token = window.localStorage.getItem("token");

    try {
      await axios.delete(`https://api.thekuasar.com/activities/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Update the activities list after deletion
      const updatedActivities = activities.filter(
        (activity) => activity.id !== id
      );
      setActivities(updatedActivities);
    } catch (error) {
      console.error("Error deleting activity:", error);
      setError(error);
    }
  };

  return (
    <div className="page-size">
      <Flex
        flexDirection={{ base: "column" }}
        height={"fit-content"}
        overflowX={"hidden"}
        backgroundColor="gray.200"
        alignItems="center"
      >
        <Heading color="black" marginTop={3} marginBottom={2}>
          ACTIVITIES CREATED BY ADMIN
        </Heading>

        <Flex>
          <Link to="/admin/create-activity">
            <Button
              marginRight={2}
              colorScheme="blue"
              size="sm"
              marginTop={2}
              marginBottom={2}
            >
              Create Activity
            </Button>
          </Link>
          <Spacer />
          <Link to="/admin/user-activities">
            <Button
              marginLeft={2}
              colorScheme="blue"
              size="sm"
              marginTop={2}
              marginBottom={2}
            >
              See User Activities by Task
            </Button>
          </Link>
        </Flex>

        <Box overflowY={"scroll"}>
          <Table
            variant="striped"
            colorScheme="blue"
            width="100%"
            height={"fit-content"}
            className="responsiveTable"
          >
            <Thead
              position="sticky"
              top={0}
              zIndex="docked"
              backgroundColor="gray.200"
            >
              <Tr>
                <Th>No</Th>
                <Th>Time Start</Th>
                <Th>Time End</Th>
                <Th>Title</Th>
                <Th>Instruction</Th>
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {activities.length > 0 ? (
                activities.map((activity, index) => (
                  <Tr key={activity.id}>
                    <Td>{index + 1}</Td>
                    <Td>{formatDateTime(activity.date_begin)}</Td>
                    <Td>{formatDateTime(activity.date_end)}</Td>
                    <Td>{activity.title}</Td>
                    <Td>{activity.instruction}</Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        size="sm"
                        as={Link}
                        to={`/admin/update-activity/${activity.id}`}
                      >
                        Edit
                      </Button>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleDeleteClick(activity.id)}
                        marginLeft={2}
                      >
                        Delete
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="7">No activities found</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>

        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>
              An error occurred while fetching activities:
            </AlertTitle>
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}

      <Box>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          width="100%"
          height="100%"
        >
          <AlertDialogOverlay
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            overflowX={"scroll"}
          >
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Activity
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? All user activities related with this Activity
                will be deleted as well.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onClose();
                    handleDelete(activityToDelete); // Use activityToDelete
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        </Box>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next"
          onPageChange={(selectedPage) =>
            handlePageChange(selectedPage.selected + 1)
          }
          pageCount={pagesQuantity}
          previousLabel="previous"
          renderOnZeroPageCount={false}
          containerClassName="pagination-container"
          pageClassName="pagination-page"
          breakClassName="pagination-break"
          previousClassName="pagination-previous"
          nextClassName="pagination-next"
          activeClassName="pagination-active"
        />
      </Flex>
    </div>
  );
};

export default ReadActivity;
