import React, { useState, useRef, useEffect } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Flex,
  Heading,
  Input,
  Button,
  FormLabel,
  Stack,
  Box,
  FormControl,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogContent,
  HStack,
  Textarea,
  Link,
  useClipboard,
  Text,
} from "@chakra-ui/react";

function CreateActivity() {
  const navigate = useNavigate();
  const cancelRef = useRef();

  const [formData, setFormData] = useState({
    title: "",
    instruction: "",
    date_begin: "",
    date_end: "",
    narrations: [{ text: "", media: [""], retweet: "" }],
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState(null);
  const [retweetDisabled, setRetweetDisabled] = useState(false);
  const [narrationDisabled, setNarrationDisabled] = useState(false);
  const { hasCopied, onCopy } = useClipboard(
    `https://demoportal.thekuasar.com/task/${response?.data?.Activities?.id}`
  );

  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const handleInstructionChange = (e) => {
    setFormData({ ...formData, instruction: e.target.value });
  };

  const handleDateBeginChange = (e) => {
    setFormData({ ...formData, date_begin: e.target.value });
  };

  const handleDateEndChange = (e) => {
    setFormData({ ...formData, date_end: e.target.value });
  };

  const handleNarrationTextChange = (e, index) => {
    const { value } = e.target;
    const updatedNarrations = [...formData.narrations];
    updatedNarrations[index].text = value;
    setFormData({ ...formData, narrations: updatedNarrations });

    // Check if "Tweet Narration" field is empty to enable/disable "Retweet URL" field
    if (value.trim() === "") {
      setRetweetDisabled({ ...retweetDisabled, [index]: false }); // Enable "Retweet URL" for this specific narration
    } else {
      setRetweetDisabled({ ...retweetDisabled, [index]: true }); // Disable "Retweet URL" for this specific narration
    }
  };

  const handleNarrationRetweetChange = (e, index) => {
    const { value } = e.target;
    const updatedNarrations = [...formData.narrations];
    updatedNarrations[index].retweet = value;
    setFormData({ ...formData, narrations: updatedNarrations });

    // Check if "Retweet URL" field is empty to enable/disable "Narration text" field
    if (value.trim() === "") {
      setNarrationDisabled({ ...narrationDisabled, [index]: false }); // Enable "Narration text" for this specific narration
    } else {
      setNarrationDisabled({ ...narrationDisabled, [index]: true }); // Disable "Narration text" for this specific narration
    }
  };

  const handleAddNarration = () => {
    setFormData((prevData) => ({
      ...prevData,
      narrations: [...prevData.narrations, { text: "", media: [""] }],
    }));
  };

  const handleRemoveNarration = (index) => {
    setFormData((prevData) => {
      const updatedNarrations = [...prevData.narrations];
      updatedNarrations.splice(index, 1);
      return { ...prevData, narrations: updatedNarrations };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = window.localStorage.getItem("token");

    const formDataToSend = new FormData();

    formDataToSend.append("title", formData.title);
    formDataToSend.append("instruction", formData.instruction);
    formDataToSend.append("date_begin", formData.date_begin);
    formDataToSend.append("date_end", formData.date_end);

    // Loop through each narration
    formData.narrations.forEach((narration, index) => {
      // Append narration text
      formDataToSend.append(`narrations_${index}`, narration.text);
      formDataToSend.append(`tweet_url_${index}`, narration.retweet);
    });

    try {
      const apiResponse = await axios.post(
        "https://api.thekuasar.com/activities/create",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Activity created:", apiResponse.data);
      setSuccess("Activity created!");
      setResponse(apiResponse.data);

      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error creating activity:", error);
      setError("Error creating activity:", error);
    }
  };

  // Use useEffect to handle response changes
  useEffect(() => {
    if (response && success) {
      setIsPopupOpen(true);
    }
  }, [response, success]);

  return (
    <div style={{ overflowY: "auto" }}>
      <Flex
        flexDirection="column"
        width="100wh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
          marginBottom={5}
        >
          <Box minW={{ base: "90%", md: "550px" }} paddingX={3} paddingY={3}>
            <form onSubmit={handleSubmit}>
              <Stack
                spacing={4}
                justifyContent="center"
                alignItems="center"
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <Heading color="black" marginBottom={1}>
                  CREATE ACTIVITY
                </Heading>
                <HStack spacing={4}>
                  <Box>
                    <FormControl>
                      <FormLabel>Date Time Begin</FormLabel>
                      <Input
                        placeholder="Select Date and Time"
                        size="md"
                        type="datetime-local"
                        value={formData.date_begin}
                        onChange={handleDateBeginChange}
                        required
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl>
                      <FormLabel>Date Time End</FormLabel>
                      <Input
                        placeholder="Select Date and Time"
                        size="md"
                        type="datetime-local"
                        value={formData.date_end}
                        onChange={handleDateEndChange}
                        required
                      />
                    </FormControl>
                  </Box>
                </HStack>

                <FormControl>
                  <FormLabel>Title</FormLabel>
                  <Input
                    type="text"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleTitleChange}
                    required
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Instruction</FormLabel>
                  <Textarea
                    type="text"
                    placeholder="Instruction"
                    value={formData.instruction}
                    onChange={handleInstructionChange}
                  />
                </FormControl>

                {formData.narrations.map((narration, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <Text fontSize="sm" color="gray.500">
                      Please fill either the narration text or the retweet URL,
                      but not both.
                    </Text>

                    <FormControl marginTop={3} marginBottom={3}>
                      <FormLabel>Tweet Narration</FormLabel>
                      <Textarea
                        type="text"
                        placeholder="Tweet Narration"
                        value={formData.narrations[index].text}
                        onChange={(e) => handleNarrationTextChange(e, index)}
                        required
                        disabled={narrationDisabled[index]}
                      />
                    </FormControl>

                    <FormControl marginBottom={3}>
                      <FormLabel>Retweet URL</FormLabel>
                      <Textarea
                        type="text"
                        placeholder="Tweet URL"
                        value={formData.narrations[index].retweet}
                        onChange={(e) => handleNarrationRetweetChange(e, index)}
                        required
                        disabled={retweetDisabled[index]}
                      />
                    </FormControl>

                    {index > 0 && (
                      <Button
                        variant="outline"
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleRemoveNarration(index)}
                      >
                        Remove Narration
                      </Button>
                    )}
                  </div>
                ))}
                <Button
                  variant="outline"
                  colorScheme="blue"
                  onClick={handleAddNarration}
                >
                  Add Narration
                </Button>
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="blue"
                  width="full"
                >
                  Submit
                </Button>
                <Link width="full" href="/admin/activities">
                  <Button
                    borderRadius={0}
                    variant="outline"
                    colorScheme="red"
                    width="full"
                  >
                    Cancel
                  </Button>
                </Link>
                {error && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Create activity failed</AlertTitle>
                    <AlertDescription>{error.message}</AlertDescription>
                  </Alert>
                )}
                {success && (
                  <Alert status="success">
                    <AlertIcon />
                    <AlertTitle>Create activity success!</AlertTitle>
                    <AlertDescription>{success}</AlertDescription>
                  </Alert>
                )}
              </Stack>
            </form>
          </Box>
        </Stack>
        {/* Tampilkan pop-up jika isPopupOpen true */}
        <AlertDialog
          isOpen={isPopupOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsPopupOpen(false)} // Tutup pop-up saat tombol Cancel ditekan
          width={"100%"}
        >
          <AlertDialogOverlay width={"100%"} justifyContent={"center"}>
            <AlertDialogContent
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold" width={"100%"}>
                Activity Created!
              </AlertDialogHeader>

              <AlertDialogBody width={"100%"}>
                {success && (
                  <>
                    Your activity has been successfully created. Here's the
                    link:
                    <br />
                    <ReactLink to={`/task/${response?.data?.Activities?.id}`}>
                      <Text color={"blue.500"}>View Activity</Text>
                    </ReactLink>
                    <br />
                    <Flex alignItems="center">
                      <Input
                        value={`https://demoportal.thekuasar.com/task/${response?.data?.Activities?.id}`}
                        isReadOnly
                        placeholder={`https://demoportal.thekuasar.com/task/${response?.data?.Activities?.id}`}
                      />
                      <Button onClick={onCopy} ml={2}>
                        {hasCopied ? "Copied" : "Copy"}
                      </Button>
                    </Flex>
                  </>
                )}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setIsPopupOpen(false); // Tutup pop-up saat tombol OK ditekan
                    navigate("/admin/activities"); // Navigasi ke halaman aktivitas
                  }}
                >
                  OK
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </div>
  );
}

export default CreateActivity;
