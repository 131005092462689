function formatDateTime(dateTimeString) {
    const namaBulan = [
      "Januari", "Februari", "Maret", "April", "Mei", "Juni",
      "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
  
    const date = new Date(dateTimeString);
    const tanggal = date.getDate();
    const bulan = namaBulan[date.getMonth()];
    const tahun = date.getFullYear();
    const jam = date.toLocaleTimeString("id-ID", { hour: "2-digit", minute: "2-digit" });
  
    return `${tanggal} ${bulan} ${tahun}, ${jam} WIB`;
  }
  
  export default formatDateTime;
  